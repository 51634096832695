import {
  BookingDetailsDefaultValues,
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { AddDocumentsWithPreview } from "../../components/UI/AllWebCheckInForms/DocumentUpload";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import AppStandardButton from "../../components/UI/AppButtons";
import { AxiosResponse } from "axios";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { BookingApi } from "../../API/Apis/BookingApi";
import { ByReservationNumberForm } from "../../components/UI/AllWebCheckInForms/ReservationDetailsByReservationNumber";
import { ByVoucherNumberForm } from "../../components/UI/AllWebCheckInForms/ReservationDetailsByVoucherForm";
import { CompanyForm } from "../../components/UI/AllWebCheckInForms/CompanyForm";
import { CreateNewBooking } from "../../@types/DTO/Booking";
import { CustomerDetailsResponse_2 } from "../../@types/DTO/ApiResponses";
import { DriverAddForm } from "../../components/Layouts/DriverAddForm/DriverAddForm";
import { IndividualUserForms } from "../../components/UI/AllWebCheckInForms/IndividualUserForms";
import { Modal } from "../../components/UI/AppModal";
import {
  PrivacyPolicy,
  SinglePrivacyPolicy,
} from "../../@types/DTO/privacyPolicy";
import { SoleTraderForm } from "../../components/UI/AllWebCheckInForms/SoleTraderForm";
import { StandardCard } from "../../components/UI/AppCards";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../components/UI/AppModal/DialogBox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { formatTheme } from "../../config/MuiColorThemes";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection";
import i18n, { t } from "i18next";
import BookingGetters from "../../store/onlineBooking/getters";
import Collapsible from "./Collapsible";
import { TextResource } from "../../@types/DTO/AppSetting";
interface DriverDetail {
  identificationDocuments: File[];
  drivingLicenseDocuments: File[];
}
function WebCheckInPage(): JSX.Element {
  type RESERVATION_TYPE = "I have Voucher Number" | "I have Reservation Number";
  const [reservationType, setReservationType] = useState<RESERVATION_TYPE>(
    "I have Reservation Number"
  );
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);

  const ReservationOptions: Array<RESERVATION_TYPE> = [
    "I have Reservation Number",
    "I have Voucher Number",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialCustomerDetails, setInitialCustomerDetails] =
    useState<DetailsFormValues>({
      ...DefaultValuesDetailsForm,
    });
  const [currentCustomerDetails, setCurrentCustomerDetails] =
    useState<DetailsFormValues>({
      ...initialCustomerDetails,
    });
  const [reservationDetails, setReservationDetails] =
    useState<CreateNewBooking>({
      ...BookingDetailsDefaultValues,
    });

  const [initialDriverValues, setInitialDriverValues] = useState<
    DetailsFormValues[]
  >([]);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDriverDetails, setCurrentDriverDetails] =
    useState<DetailsFormValues>({ ...DefaultValuesDetailsForm });
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [privacyPolicyLength, setPrivacyPolicyLength] = useState(0);
  const [allDriverDetails, setAllDriverDetails] = useState<DetailsFormValues[]>(
    []
  );
  const count = allDriverDetails.length + 1;
  const [allPrivacyPolicy, setAllPrivacyPolicy] = useState<
    PrivacyPolicy["privacyPolicy"]
  >([]);
  const [selectedPrivacyPolicy, setSelectedPrivacyPolicy] = useState<string[]>(
    []
  );
  const [openDriverAddDialog, setOpenDriverAddDialog] =
    useState<boolean>(false);
  const [addingDriver, setAddingDriver] = useState<boolean>(false);
  const [driverLicensedocument, setDriverLicenceDocument] = useState<File[]>(
    []
  );
  const [currentModifyingDriverIndex, setCurrentModifyingDriverIndex] =
    useState<number>(-1);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const onRequestClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };
  const textResource = BookingGetters.getTextResource();
  const [vatLabel, setVatLabel] = useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const vatTextData = data.find((item) => item.key === "vatLabel");
    setVatLabel(vatTextData?.body || "");
  }, [textResource]);
  const DialogueConfirmationForAddingMoreDriver = async () => {
    if (currentStep == 1 && allDriverDetails.length < 3) {
      if (currentCustomerDetails.customerType.toLowerCase() == "company") {
        setAddingDriver(true);
      } else {
        setOpenDriverAddDialog(true);
      }
      return Promise.reject(); // is adding Driver
    }
    return Promise.resolve(); // is not adding driver
  };
  const driverButtonsStytlingConfig = [
    {
      icon: "/assets/icons/blue-driver-icon.png",
      color: "#004188",
    },
    {
      icon: "/assets/icons/yellow-driver-icon.png",
      color: "#F9ca01",
    },
    {
      icon: "/assets/icons/red-driver-icon.png",
      color: "#fe0000",
    },
  ];
  const [document, setDocument] = useState<File[]>([]);
  const [privacyPolicies, setPrivacyPolicies] = useState<SinglePrivacyPolicy[]>(
    []
  );
  const [privacyPolicyIds, setPrivacyPolicyIds] = useState<number[]>([]);
  const [selectedPrivacyLength, setSelectedPrivacyLength] = useState(0);
  const areAllDriverDocumentsUploaded = () => {
    if (allDriverDetails) {
      const incompleteDrivers = allDriverDetails.filter((driver) => {
        const idDocsUploaded = driver?.identificationDocuments?.length > 0;
        const licenseDocsUploaded = driver?.drivingLicenseDocuments?.length > 0;
        const customerDocsUploaded = driver?.customerDocs
          ? driver.customerDocs.length > 0
          : false;
        return (
          !(idDocsUploaded && licenseDocsUploaded) && !customerDocsUploaded
        );
      });
      return incompleteDrivers.length === 0;
    }
    return false;
  };
  const updateSelectedPrivacyPolicy = (index, value) => {
    value.forEach((e) => {
      const p = privacyPolicies.filter(
        (el) => el.privacyPolicyLocalLang == e
      )[0];
      if (!privacyPolicyIds.includes(p.id)) {
        setPrivacyPolicyIds((prev) => [...prev, p.id]);
      }
    });
    setSelectedPrivacyPolicy(value);
    setSelectedPrivacyLength(value.length);
  };

  const FormButtons = () => {
    return (
      <ThemeProvider theme={dynamicTheme}>
        <div className="box-border">
          {errorMessage ? (
            <p className="text-red-800 bg-red-200 box-border px-4 py-1 text-center rounded-lg">
              {errorMessage}
            </p>
          ) : (
            ""
          )}
          {currentStep == 1 && (
            <>
              <div>
                {" "}
                <p className="text-black text-md pb-2 font-semibold pt-2">
                  {t("You can add maximum of three drivers")}
                </p>
              </div>
              <div className="flex gap-2 flex-wrap">
                {allDriverDetails.map((data, index) => {
                  return (
                    <AppStandardButton
                      key={index}
                      variant="text"
                      clickHandler={() => {
                        setAddingDriver(true);
                        setCurrentDriverDetails(allDriverDetails[index]);
                        setCurrentModifyingDriverIndex(index);
                      }}
                    >
                      <img
                        src={driverButtonsStytlingConfig[index].icon}
                        className="w-5 h-5 mr-2"
                      />
                      <p
                        className={`text-[${driverButtonsStytlingConfig[index].color}] normal-case`}
                      >
                        {index === 0
                          ? t("Edit first Driver")
                          : index === 1
                          ? t("Edit second Driver")
                          : index === 2
                          ? t("Edit third Driver")
                          : "Edit Driver"}
                        {lang === "en" ? ` ${index + 1}` : ""}
                      </p>
                    </AppStandardButton>
                  );
                })}
                {allDriverDetails.length < 3 && (
                  <div
                    className={`w-fit cursor-pointer text-md text-[${
                      driverButtonsStytlingConfig[
                        Math.max(allDriverDetails.length, 1) - 1
                      ].color
                    }]`}
                    onClick={() => {
                      setAddingDriver(true);
                    }}
                  >
                    <div className="flex items-center mt-1">
                      <img
                        src="/assets/icons/red-driver-icon.png"
                        className="w-5 h-5 mr-2"
                      />
                      <p className="text-red-800 font-semibold">
                        {t("Add Driver")}
                        {"  "}
                        {"("}
                        {"€"}
                        {reservationDetails?.additionalDriverFee?.toFixed(2)}
                        <span className="text-red-800 font-semibold ml-[5px]">
                          {vatLabel}
                        </span>
                        {")"}
                        <input type="text" style={{ height: 0, width: 0 }} />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={"flex flex-row flex-wrap box-border pt-8 gap-2"}>
            {currentStep > 0 && (
              <AppStandardButton
                variant="outlined"
                color="primary"
                className="w-1/2"
                clickHandler={() => {
                  setCurrentStep((prev) => Math.max(prev - 1, 0));
                }}
              >
                <p className="text-black normal-case text-center w-full font-medium text-xl">
                  {t("Prev")}
                </p>
              </AppStandardButton>
            )}
            <AppStandardButton
              disabled={
                appSettings?.customerAndDriverDocumentRequired &&
                currentStep === 2 &&
                (privacyPolicyLength !== selectedPrivacyLength ||
                  !areAllDriverDocumentsUploaded() ||
                  ((document.length === 0 ||
                    driverLicensedocument.length === 0) &&
                    currentCustomerDetails?.customerDocs?.length === 0))
              }
              loading={isSaving}
              className={"w-fit grow"}
              color={"primary"}
              type={"submit"}
            >
              <p className="normal-case text-center w-full font-medium text-xl text-[#ffffff]">
                {t("Next")}
              </p>
            </AppStandardButton>
          </div>
        </div>
      </ThemeProvider>
    );
  };
  useEffect(() => {
    if (
      currentStep > 0 &&
      !(currentCustomerDetails.firstName || currentCustomerDetails.lastName)
    ) {
      // setCurrentStep(0);
    }
    if (currentCustomerDetails.customerType.toLowerCase() === t("company")) {
      setCurrentCustomerDetails((prev) => ({
        ...prev,
        isSignatoryTheMainDriver: "NO",
      }));
    }
  }, []);
  const appSettings = BookingGetters?.getAppSettings();
  const webCheckinDocumentRequired =
    appSettings?.customerAndDriverDocumentRequired;
  function setDriverDetailsAndInitialDriverIndexAtIndex(
    driverDetails: CustomerDetailsResponse_2,
    index: number
  ) {
    setAllDriverDetails((prev: DetailsFormValues[]) => {
      prev[index] = {
        ...DefaultValuesDetailsForm,
        ...driverDetails,
        isDriver: !driverDetails.isDriver ? "YES" : "NO",
        reservationNumber: JSON.stringify(reservationDetails.reservationId),
      };
      return [...prev];
    });

    setInitialDriverValues((prev) => {
      prev[index] = {
        ...DefaultValuesDetailsForm,
        ...driverDetails,
        isDriver: !driverDetails.isDriver ? "YES" : "NO",
        reservationNumber: JSON.stringify(reservationDetails.reservationId),
      };
      return [...prev];
    });
  }
  useEffect(() => {
    setAllDriverDetails([]);
    if (reservationDetails.driver1Id) {
      BookingApi.getCustomerDetails(reservationDetails.driver1Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(
          {
            ...e.data,
          },
          0
        );
      });
    }
    if (reservationDetails.driver2Id) {
      setAllDriverDetails([]);
      BookingApi.getCustomerDetails(reservationDetails.driver2Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(e.data, 1);
      });
    }
    if (reservationDetails.driver3Id) {
      setAllDriverDetails([]);
      BookingApi.getCustomerDetails(reservationDetails.driver3Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(e.data, 2);
      });
    }
  }, [reservationDetails]);
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);

  /**
   *
   *  FUNCTIONS
   *
   */

  const [documentTypes, setDocumentTypes] = useState([]);
  async function updateReservationDetails(customerDetails: DetailsFormValues) {
    await setIsSaving(true);
    await setCurrentCustomerDetails({ ...customerDetails });
    await BookingApi.updateCustomerDetails(
      reservationDetails.customerId,
      currentCustomerDetails
    );
    await BookingApi.addDrivers(reservationDetails.reservationId, [
      allDriverDetails[0],
      allDriverDetails[1],
      allDriverDetails[2],
    ]);
    await setCurrentStep(currentStep + 1);
    await setIsSaving(false);
  }
  async function getBookingDetailsByReservationNumber(): Promise<void> {
    try {
      setErrorMessage("");

      if (currentCustomerDetails.reservationDate == null) {
        setErrorMessage("Reservation Date not provided");
        return;
      }
      const reservationApiResponse: AxiosResponse<CreateNewBooking> =
        await BookingApi.getBookingDetailsByBookingDateAndReservationCode(
          currentCustomerDetails.reservationPrefix || "",
          currentCustomerDetails.reservationNumber || "",
          currentCustomerDetails.reservationDate as Date
        );
      if (reservationApiResponse.data.status === "error") {
        setModalMessage(
          reservationApiResponse.data.msg || "Something went wrong"
        );
        setOpenModal(true);
      } else {
        getPrivacyPolicy(reservationApiResponse.data.reservationId);
        setDocumentTypes(reservationApiResponse.data.documentTypes);
        const customerDetails: AxiosResponse<CustomerDetailsResponse_2> =
          await BookingApi.getCustomerDetails(
            reservationApiResponse.data.customerId
          );
        const tempValues: DetailsFormValues = {
          ...currentCustomerDetails,
          ...customerDetails.data,
          isDriver: customerDetails.data.isDriver ? "YES" : "NO",
          customerType: customerDetails.data.customerType.toUpperCase(),
        };
        setInitialCustomerDetails({ ...tempValues });

        setReservationDetails({ ...reservationApiResponse.data });
        await setCurrentCustomerDetails({ ...tempValues });

        await setCurrentStep((prev) => Math.min(prev + 1, allStep.length - 1));
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getBookingDetailsByVoucherCodeAndFullName(): Promise<void> {
    try {
      setErrorMessage("");
      const reservationApiResponse =
        await BookingApi.getBookingDetailsByVoucherCode(
          currentCustomerDetails.reservationVoucher || "",
          currentCustomerDetails.firstName || "",
          currentCustomerDetails.lastName || "",
          currentCustomerDetails.companyName || ""
        );
      if (reservationApiResponse?.data?.status == "error") {
        setOpenModal(true);
        setModalMessage(
          reservationApiResponse.data.msg || "Something went wrong"
        );
      } else {
        getPrivacyPolicy(reservationApiResponse.data.reservationId);
        const customerDetails: AxiosResponse<CustomerDetailsResponse_2> =
          await BookingApi.getCustomerDetails(
            reservationApiResponse.data.customerId
          );
        const tempValues: DetailsFormValues = {
          ...currentCustomerDetails,
          ...customerDetails.data,
          isDriver: customerDetails.data.isDriver ? "YES" : "NO",
          customerType: customerDetails.data.customerType.toUpperCase(),
        };
        setInitialCustomerDetails({ ...tempValues });
        setReservationDetails({
          ...reservationApiResponse.data,
        });
        await setCurrentCustomerDetails({ ...tempValues });
        await setCurrentStep((prev) => Math.min(prev + 1, allStep.length - 1));
      }
    } catch (err) {
      //console.log(err);
    }
  }
  const [requiredPolicy, setRequiredPrivacyPolicy] = useState<string[]>([]);
  const lang = i18n.language;

  function getPrivacyPolicy(reservationId: number) {
    BookingApi.getPrivacyPolicy(reservationId, "booking").then((response) => {
      const fetchedPolicies = response.data.privacyPolicy.map((item) => {
        const policyGroup = item.policyGroup;
        return policyGroup;
      });
      setAllPrivacyPolicy(fetchedPolicies);
      const selectedPolicies = response.data.selectedPolicies;
      const selectedfetchedPolicies = fetchedPolicies.map((policyGroup) =>
        policyGroup
          .filter((el) => {
            const isSelected = selectedPolicies.includes(el.id);
            return isSelected;
          })
          .map((el) =>
            lang === "it"
              ? el.privacyPolicyLocalLang
              : lang === "en"
              ? el.privacyPolicyEn
              : null
          )
      );
      const defaultSelectedPolicies = fetchedPolicies
        .map((policyGroup) => {
          const defaultPolicy = policyGroup.find(
            (policy) => (policy.isDefaultAccepted = true)
          );
          return defaultPolicy
            ? i18n.language == "it"
              ? defaultPolicy.privacyPolicyLocalLang
              : defaultPolicy.privacyPolicyEn
            : null;
        })
        .filter((policy) => policy !== null);
      setSelectedPrivacyPolicy(selectedfetchedPolicies);

      const requiredPolicy = fetchedPolicies.flatMap((policyArray) =>
        policyArray
          .filter((policy) => policy.isMandatory)
          .map((policy) =>
            i18n.language == "it"
              ? policy.privacyPolicyLocalLang
              : policy.privacyPolicyEn
          )
      );
      setRequiredPrivacyPolicy(requiredPolicy);
    });
  }
  let i = 1;
  const allStep = [
    {
      label: t("Reservation/Voucher details"),
      component:
        t(reservationType) === t("I have Reservation Number") ? (
          <ThemeProvider theme={dynamicTheme}>
            <ByReservationNumberForm
              onSubmit={async (e) => {
                await setIsSaving(true);
                await setCurrentCustomerDetails({
                  ...currentCustomerDetails,
                  reservationPrefix: e.reservationPrefix,
                  reservationNumber: e.reservationNumber,
                  reservationDate: e.reservationDate,
                });
                await getBookingDetailsByReservationNumber();
                await setIsSaving(false);
              }}
              onChange={(e) => {
                setCurrentCustomerDetails((prev) => {
                  return { ...prev, ...e };
                });
              }}
              values={currentCustomerDetails}
              dynamicTheme={dynamicTheme}
            >
              <FormButtons />
            </ByReservationNumberForm>
          </ThemeProvider>
        ) : (
          <ThemeProvider theme={dynamicTheme}>
            <ByVoucherNumberForm
              onSubmit={async (e) => {
                await setIsSaving(true);
                await setCurrentCustomerDetails({
                  ...currentCustomerDetails,
                  reservationVoucher: e.reservationVoucher,
                  firstName: e.firstName,
                  lastName: e.lastName,
                  companyName: e.companyName,
                });
                await getBookingDetailsByVoucherCodeAndFullName();
                await setIsSaving(false);
              }}
              onChange={(e) => {
                setCurrentCustomerDetails((prev) => {
                  return { ...prev, ...e };
                });
              }}
            >
              <FormButtons />
            </ByVoucherNumberForm>
          </ThemeProvider>
        ),
      onSubmit: async () => {},
    },
    {
      label: t("Customer Details"),
      component: (
        <ThemeProvider theme={dynamicTheme}>
          <div>
            <AppRadioButton
              color={"primary"}
              label={t("Are you an Individual, company or sole trader")}
              value={t(currentCustomerDetails.customerType.toLowerCase())}
              readOnly={true}
              options={["Individual", "Company", "Sole Trader"].map((el) => {
                return t(el.toLowerCase());
              })}
            />

            {t(
              currentCustomerDetails.customerType.toLowerCase()
            ).toLowerCase() == t("individual").toLowerCase() && (
              <IndividualUserForms
                onSubmit={async (e) => {
                  setIsSaving(true);
                  await BookingApi.updateCustomerDetails(
                    reservationDetails.customerId,
                    currentCustomerDetails
                  )
                    .then(() => {
                      return DialogueConfirmationForAddingMoreDriver();
                    })
                    .then(async () => {
                      await BookingApi.addDrivers(
                        reservationDetails.reservationId,
                        [
                          allDriverDetails[0],
                          allDriverDetails[1],
                          allDriverDetails[2],
                        ]
                      );
                      setCurrentStep(currentStep + 1);
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
                initialValues={initialCustomerDetails}
                values={currentCustomerDetails}
                onChange={(e) => {
                  setCurrentCustomerDetails((prev: DetailsFormValues) => {
                    return { ...prev, ...e };
                  });
                }}
              >
                <FormButtons />
              </IndividualUserForms>
            )}

            {t(
              currentCustomerDetails.customerType.toLowerCase()
            ).toLowerCase() == t("company").toLowerCase() && (
              <CompanyForm
                onSubmit={async (e) => {
                  await updateReservationDetails({
                    ...currentCustomerDetails,
                    ...e,
                  });
                }}
                initialValues={initialCustomerDetails}
                values={currentCustomerDetails}
                // onChange={(e) => {
                //   setCurrentCustomerDetails((prev) => {
                //     return { ...prev, ...e };
                //   });
                // }}
              >
                <FormButtons />
              </CompanyForm>
            )}
            {t(
              currentCustomerDetails.customerType.toLowerCase()
            ).toLowerCase() == t("sole trader").toLowerCase() && (
              <SoleTraderForm
                onSubmit={async (e) => {
                  await updateReservationDetails({
                    ...currentCustomerDetails,
                    ...e,
                  });
                }}
                initialValues={initialCustomerDetails}
                values={currentCustomerDetails}
                onChange={(e) => {
                  setCurrentCustomerDetails((prev) => {
                    return { ...prev, ...e };
                  });
                }}
              >
                <FormButtons />
              </SoleTraderForm>
            )}
            <Modal
              isOpen={addingDriver}
              handleClose={async () => {
                if (currentModifyingDriverIndex >= 0) {
                  await setCurrentDriverDetails(DefaultValuesDetailsForm);
                }
                setCurrentModifyingDriverIndex(-1);

                setAddingDriver(false);
                // console.log(currentDriverDetails);
              }}
            >
              <h2 className="box-border py-2 font-bold text-xl">
                Driver Details
              </h2>
              <div className="w-full">
                <div className="h-[60vh] overflow-scroll overflow-x-hidden box-border px-4">
                  <DriverAddForm
                    onSubmit={async (e) => {
                      if (!e.firstName || e.firstName.trim() === "") {
                        setErrorMessage("First name is required");
                        return;
                      }
                      if (!e.lastName || e.lastName.trim() === "") {
                        setErrorMessage("Last name is required");
                        return;
                      }
                      if (!e.email || e.email.trim() === "") {
                        setErrorMessage("Email is required");
                        return;
                      }
                      setErrorMessage(""); // Clear error message on successful submission
                      await setCurrentDriverDetails({
                        ...DefaultValuesDetailsForm,
                      });
                      setAddingDriver(false);
                      await setAllDriverDetails((prev) => {
                        const prevEmails = prev.map((e) => {
                          return e.email;
                        });
                        // Check if the email already exists in the array
                        const isEmailDuplicate = prevEmails.some(
                          (email) => email === e.email
                        );
                        if (isEmailDuplicate) {
                          console.log("Duplicate email found:", e.email);
                          return prev; // No changes, keep the previous state as-is
                        }
                        if (currentModifyingDriverIndex >= 0) {
                          prev.splice(currentModifyingDriverIndex, 1, {
                            ...e,
                            customerId: currentCustomerDetails.customerId,
                            reservationNumber:
                              reservationDetails.reservationId.toString(),
                          });
                          return [...prev];
                        }
                        return [
                          ...prev,
                          {
                            ...e,
                            customer_id: currentCustomerDetails.customerId,
                            reservationNumber:
                              reservationDetails.reservationId.toString(),
                          },
                        ];
                      });
                      setCurrentModifyingDriverIndex(-1);
                    }}
                    driverDetails={currentDriverDetails}
                    documentTypes={documentTypes}
                    initialValues={
                      currentModifyingDriverIndex < 0
                        ? DefaultValuesDetailsForm
                        : initialDriverValues[currentModifyingDriverIndex]
                    }
                    onChange={(e) => {
                      setCurrentDriverDetails({
                        ...currentDriverDetails,
                        ...e,
                      });
                    }}
                  >
                    <div className="flex justify-end mr-10 items-center">
                      {" "}
                      {errorMessage && (
                        <span className="text-red-500 mr-4">
                          {errorMessage}
                        </span>
                      )}
                      <AppStandardButton type="submit" className={"w-1/4"}>
                        {t("Save")}
                      </AppStandardButton>
                    </div>
                  </DriverAddForm>
                </div>
              </div>
            </Modal>
          </div>
        </ThemeProvider>
      ),
      onSubmit: async () => {
        await setIsSaving(true);
        await BookingApi.updateCustomerDetails(
          reservationDetails.customerId,
          currentCustomerDetails
        );
        await setCurrentStep(currentStep + 1);
        await setIsSaving(false);
      },
    },

    {
      label: t("Customer Documents"),
      component: (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsSaving(true);
            BookingApi.addDocuments(
              parseInt(initialCustomerDetails.customerId),
              document,
              driverLicensedocument
            )
              .then(() => {
                return BookingApi.addDrivers(reservationDetails.reservationId, [
                  allDriverDetails[0],
                  allDriverDetails[1],
                  allDriverDetails[2],
                ]);
              })
              .then(() => {
                navigate("/SuccessPage");
              })
              .finally(() => {
                setIsSaving(false);
              });
            const policyIds = selectedPrivacyPolicy.map((selectedPolicy) => {
              const selectedPolicyIds: number[] = [];
              let foundPolicy: number | undefined;
              if (Array.isArray(selectedPolicy)) {
                for (const p1 of selectedPolicy) {
                  let policyFound = false;
                  for (const p2 of allPrivacyPolicy) {
                    const foundPolicy = p2.find((policy) => {
                      const policyData = p1.startsWith("*")
                        ? p1.substring(1)
                        : p1;
                      return policyData === policy.privacyPolicyLocalLang;
                    });
                    if (foundPolicy) {
                      selectedPolicyIds.push(foundPolicy.id);
                      policyFound = true;
                      break;
                    }
                  }
                  if (!policyFound) {
                    selectedPolicyIds.push(-1);
                  }
                }
              }
              return selectedPolicyIds;
            });
            BookingApi.savePrivacyPolicy(
              reservationDetails.reservationId,
              policyIds.flat()
            ); // Flatten nested arrays before saving for single array
          }}
        >
          <p className="font-semibold mb-2">
            {t("Terms & condition and Privacy policy")}
            {/*  <sup className="font-semibold text-red-500">*</sup>*/}
          </p>
          {appSettings?.showTermsAndPrivacyAtWebCheckIn && (
            <div className="flex flex-row flex-wrap gap-8 mb-8">
              <AppStandardButton
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.termsConditionFile;
                    if (url.includes("s3.amazonaws")) {
                      fetch(url).then(async (e) => {
                        const fileBlob = await e.blob();
                        const linkObj = window.document.createElement("a");
                        linkObj.href = URL.createObjectURL(fileBlob);
                        linkObj.target = "_blank";
                        linkObj.click();
                        return fileBlob;
                      });
                    } else {
                      const linkObj = window.document.createElement("a");
                      linkObj.href = url;
                      linkObj.target = "_blank";
                      linkObj.click();
                    }
                  });
                }}
                variant="contained"
                color="primary"
                style={{ borderRadius: "1" }}
                className="h-12 w-70"
              >
                {t("Download terms and conditions")}{" "}
              </AppStandardButton>

              <AppStandardButton
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.privacyfileUrl;
                    if (url.includes("s3.amazonaws")) {
                      fetch(url).then(async (e) => {
                        const fileBlob = await e.blob();
                        const linkObj = window.document.createElement("a");
                        linkObj.href = URL.createObjectURL(fileBlob);
                        linkObj.target = "_blank";
                        linkObj.click();
                        return fileBlob;
                      });
                    } else {
                      const linkObj = window.document.createElement("a");
                      linkObj.href = url;
                      linkObj.target = "_blank";
                      linkObj.click();
                    }
                  });
                }}
                variant="contained"
                color="primary"
                style={{ borderRadius: "1" }}
                className="h-12 w-70"
              >
                {t("Download the privacy policy")}{" "}
              </AppStandardButton>
            </div>
          )}
          <Collapsible
            key={1}
            title={
              <>
                {`${t(`Customer Document`)}`}
                {webCheckinDocumentRequired && (
                  <span className="text-red-500">*</span>
                )}
              </>
            }
          >
            <p className="text-base font-semibold margin">
              {t("Identification Document")}
              {webCheckinDocumentRequired && (
                <span className="text-red-500">*</span>
              )}
            </p>
            <AddDocumentsWithPreview
              onChange={(document) => {
                setDocument(document);
              }}
              selectedDocs={document}
              currentDoc={
                currentCustomerDetails?.customerDocs != null
                  ? currentCustomerDetails.customerDocs.filter(
                      (doc) => !doc?.match(/^driverlicense/i)
                    )
                  : []
              }
            />

            <p className="text-base font-semibold margin">
              {t("Driver License")}
              {webCheckinDocumentRequired && (
                <span className="text-red-500">*</span>
              )}
            </p>
            <AddDocumentsWithPreview
              onChange={(document) => {
                setDriverLicenceDocument(document);
              }}
              selectedDocs={driverLicensedocument}
              currentDoc={
                currentCustomerDetails?.customerDocs != null
                  ? currentCustomerDetails.customerDocs.filter((doc) =>
                      doc?.match(/^driverlicense/i)
                    )
                  : []
              }
            />
          </Collapsible>
          <>
            {allDriverDetails.map((el, index) => (
              <Collapsible
                key={index}
                title={
                  <>
                    {`${t("Driver Document")} ${index + 1}`}{" "}
                    {webCheckinDocumentRequired && (
                      <span className="text-red-500">*</span>
                    )}
                  </>
                }
              >
                <p className="text-base font-semibold margin">
                  {t(`Identification document for driver `)} {index + 1}
                  {webCheckinDocumentRequired && (
                    <span className="text-red-500">*</span>
                  )}
                </p>
                <AddDocumentsWithPreview
                  onChange={(files) => {
                    setAllDriverDetails((e) => {
                      const temp = [...e];
                      temp[index].identificationDocuments = files;
                      return [...temp];
                    });
                  }}
                  selectedDocs={el?.identificationDocuments || []}
                  currentDoc={
                    el?.customerDocs != null
                      ? el?.customerDocs.filter(
                          (doc) => !doc?.startsWith("driverLicenseDocument")
                        )
                      : []
                  }
                />
                <p className="text-base font-semibold margin">
                  {t(`Driving License for driver `)} {index + 1}
                  {webCheckinDocumentRequired && (
                    <span className="text-red-500">*</span>
                  )}
                </p>
                <AddDocumentsWithPreview
                  onChange={(files) => {
                    setAllDriverDetails((e) => {
                      const temp = [...e];
                      temp[index].drivingLicenseDocuments = files;
                      return [...temp];
                    });
                  }}
                  selectedDocs={el?.drivingLicenseDocuments || []}
                  currentDoc={
                    el?.customerDocs != null
                      ? el?.customerDocs.filter((doc) =>
                          doc?.startsWith("driverLicenseDocument")
                        )
                      : []
                  }
                />
              </Collapsible>
            ))}
          </>
          <AppRadioButton
            className=" font-semibold pt-2 "
            required={true}
            options={[]}
            color={"primary"}
          />
          {allPrivacyPolicy.map((e, index) => {
            const options = e.map((item) => item.privacyPolicyLocalLang || "");
            if (options.length === 0) {
              return null;
            }
            const currentIndex = i++;
            return (
              <div key={index} className="mb-4">
                <span className="text-md text-black font-bold">
                  Privacy policy {currentIndex}
                </span>
                <br />
                <AppStandardMultiSelectCheckBox
                  color="primary"
                  value={selectedPrivacyPolicy[index]}
                  options={options}
                  onChange={(value) => {
                    updateSelectedPrivacyPolicy(index, value);
                  }}
                />
              </div>
            );
          })}

          <FormButtons />
        </form>
      ),
      onSubmit: async () => {},
    },
  ];

  return (
    <ThemeProvider theme={dynamicTheme}>
      <div
        className={
          "flex flex-col justify-center items-center w-full box-border px-8 gap-4"
        }
      >
        <div>
          <AppRadioButton
            readOnly={currentStep > 0}
            label={t("select your reservation")}
            color={"primary"}
            value={t(reservationType)}
            options={ReservationOptions.map((el: RESERVATION_TYPE) =>
              t(el).toString()
            )}
            onChange={(e) => {
              setReservationType(e as RESERVATION_TYPE);
            }}
          />
        </div>
        <AlertDialog
          onCancel={() => {
            setOpenDriverAddDialog(false);
            setCurrentStep(2);
          }}
          title={
            allDriverDetails.length === 0
              ? `${t("Do you want to add first Driver")}${
                  lang === "en" ? ` ${count} ?` : ""
                }`
              : allDriverDetails.length === 1
              ? `${t("Do you want to add second Driver")}${
                  lang === "en" ? `${count} ?` : ""
                }`
              : allDriverDetails.length === 2
              ? `${t("Do you want to add third Driver")}${
                  lang === "en" ? `${count} ?` : ""
                }`
              : `${t("Do you want to add Driver number")}${
                  lang === "en" ? `${count} ?` : ""
                }`
          }
          onClose={() => {
            setOpenDriverAddDialog(false);
          }}
          open={openDriverAddDialog}
          onConfirm={() => {
            setAddingDriver(true);
            setOpenDriverAddDialog(false);
          }}
        />
        <div className={"md:w-[50%] md:p-4 p-0 w-full"}>
          <StandardCard className={" box-border md:p-4 p-0 flex flex-col"}>
            <div className="w-full box-border py-8 overflow-auto">
              <Stepper activeStep={currentStep}>
                {allStep.map((el, index) => {
                  return (
                    <Step key={index}>
                      <StepLabel color="primary-2">{el.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="flex flex-col  overflow-auto w-full grow shrink">
              {allStep.map((el, index) => {
                return (
                  index === currentStep && (
                    <div className="" key={index}>
                      <div className="box-border p-2 flex flex-col gap-2">
                        {el.component}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </StandardCard>
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={onRequestClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            paddingLeft: "4rem",
            paddingRight: "4rem",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            color: "red",
          }}
        >
          {modalMessage}
        </DialogTitle>

        <DialogActions>
          <AppStandardButton variant="outlined" onClick={onRequestClose}>
            <p className="text-black normal-case">Close</p>
          </AppStandardButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
export { WebCheckInPage };
